<template>
  <div>
    <Rates
      :rates="rates"
      @saveRate="saveRate"
      @createRate="createRate"
      @deleteRate="deleteRate"
      :delete="true"
      :create="true"
      :fee="false"
      title="Discount type"
      rate="Rate"
      ref="removeNewLine"
    />
    <v-snackbars
        :messages.sync="messages"
        color="red"
        timeout="2000"
        multi-line
        max-width="350"
        left
        rounded="lg"
        light
        elevation="4"
        content-class="snackbar"
      >
      <template v-slot:default="{ message }">
        <strong>{{ message.title }}</strong>
        <p>{{ message.text }}</p>
        <v-row v-if="message.title === 'Error'" class="ma-0 justify-end">
          <v-btn
            class="rounded-lg fz-12 fw-600 text-none border-none"
            outlined
            height="26"
            elevation="0"
            @click="doCopy('Error', message.text)"
          >Copy</v-btn>
        </v-row>
      </template>
    </v-snackbars>
    <div class="preloader" v-if="loading">
      <v-progress-circular
        indeterminate
        color="primary"
      ></v-progress-circular>
    </div>
  </div>
</template>

<script>
import Rates from '@/components/App/Plans/Rates'
import VSnackbars from 'v-snackbars'
import gql from 'graphql-tag'

export default {
  components: { Rates, 'v-snackbars': VSnackbars },
  data () {
    return {
      rates: [],
      snackbar: false,
      loading: false,
      messages: []
    }
  },
  methods: {
    checkAllDiscounts() {
      if (this.$store.getters.getAllDiscounts) {
        this.rates = this.$store.getters.getAllDiscounts
        this.rates.map(el => {
          el.value = el.rate
          el.readOnly = false
        })
      } else this.allDiscounts()
    },
    async allDiscounts () {
      this.loading = true
      await this.$apollo.query({
        query: gql`query PlansDiscounts {
          pricing {
            allDiscounts {
              description
              name
              rate
              id
            }
          }
        }`,
        fetchPolicy: 'no-cache'
      }).then((data) => {
        this.rates = data.data.pricing.allDiscounts
        this.$store.commit('updateAllDiscounts', data.data.pricing.allDiscounts)
        this.rates.map(el => {
          el.value = el.rate
          el.readOnly = false
        })
        this.loading = false
      }).catch((err) => {
        this.addSnack('item.name', 'Error', err)
        this.loading = false
        console.log(err)
      })
    },
    async saveRate (item) {
      (await this.$apollo.mutate({
        mutation: gql`mutation PlansDiscountsUpdate($input: DiscountUpdateInput! ) {
          pricing {
            discount {
              update(input: $input) {
                id
                name
                rate
                description
              }
            }
          }
        }`,
        // Parameters
        variables: {
          input: {
            description: item.description,
            id: item.id,
            name: item.name,
            rate: item.value
          }
        }
      })
        .then((data) => {
          this.addSnack(item.name, 'Success', item.name + ' was updated with success')
          this.allDiscounts()
        })
        .catch((err) => {
          this.addSnack('item.name', 'Error', err)
        }))
    },
    async createRate (item) {
      (await this.$apollo.mutate({
        mutation: gql`mutation PlansDiscountsCreate($input: DiscountCreateInput! ) {
          pricing {
            discount {
              create(input: $input) {
                id
                name
                rate
              }
            }
          }
        }`,
        // Parameters
        variables: {
          input: {
            name: item.name,
            rate: item.value,
            description: ''
          }
        }
      })
        .then((data) => {
          this.addSnack(item.name, 'Success', item.name + ' was updated with success')
          this.allDiscounts()
          this.$refs.removeNewLine.removeNewLine()
        })
        .catch((err) => {
          console.log(item)
          this.addSnack('item.name', 'Error', err)
        }))
    },
    async deleteRate (item) {
      (await this.$apollo.mutate({
        mutation: gql`mutation PlansDiscountsDelete($id: [ID!]!) {
          pricing {
            discount {
              delete(id: $id)
            }
          }
        }`,
        // Parameters
        variables: {
          id: item.id
        }
      })
        .then((data) => {
          this.addSnack(item.name, 'Success', item.name + ' was deleted with success')
          this.allDiscounts()
        })
        .catch((err) => {
          this.addSnack('item.name', 'Error', err)
        }))
    },
    addSnack (item, result, text) {
      this.messages.push({ title: result, text: text })
    },
    doCopy (action, value) {
      const vm = this
      this.$copyText(value).then(function () {
        vm.addSnack(value, 'Success', action + ' text copied')
      }, function (e) {
        console.log(e)
      })
    }
  },
  created () {
    this.checkAllDiscounts()
  }
}
</script>

<style scoped>

</style>
